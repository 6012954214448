
import {
  defineComponent,
  ref,
  onMounted,
  onBeforeMount,
  computed,
  ComputedRef,
} from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import CreateGroupModal from "@/components/modals/forms/CreateGroupModal.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import { IGroup } from "@/store/modules/GroupModule";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import router from "@/router/clean";

export default defineComponent({
  name: "groups-listing",
  components: {
    Datatable,
    CreateGroupModal,
  },
  setup() {
    const checkedGroups = ref([]);
    const tableHeader = ref([
      {
        key: "checkbox",
      },
      {
        name: "Group id",
        key: "cohortId",
        sortable: true,
      },
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Theory Teacher",
        key: "theoryTeacherName",
        sortable: true,
      },
      {
        name: "Driving Teacher",
        key: "drivingTeacherName",
        sortable: true,
      },
      {
        name: "First class",
        key: "firstClassDate",
        sortable: true,
      },
      {
        name: "State",
        key: "state",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const search = ref<string>("");

    const groups: ComputedRef<Array<IGroup>> = computed(
      () => store.state.GroupModule.groups
    );

    const displayedGroups = computed(() =>
      groups.value.filter((group) => {
        return (
          (group.cohortId &&
            group.cohortId
              .toString()
              .toLowerCase()
              .includes(search.value.toLowerCase())) ||
          (group.name &&
            group.name.toLowerCase().includes(search.value.toLowerCase())) ||
          (group.theoryTeacher?.name &&
            group.theoryTeacher.name
              .toLowerCase()
              .includes(search.value.toLowerCase())) ||
          (group.drivingTeacher?.name &&
            group.drivingTeacher.name
              .toLowerCase()
              .includes(search.value.toLowerCase())) ||
          (group.firstClassDate &&
            group.firstClassDate
              .toString()
              .toLowerCase()
              .includes(search.value.toLowerCase())) ||
          (group.state &&
            group.state.toLowerCase().includes(search.value.toLowerCase()))
        );
      })
    );

    const updateSelectedGroup = (group: IGroup) => {
      store.commit(Mutations.SET_SELECTED_GROUP, group);
      router.push({ name: "apps-groups-details" });
    };

    onBeforeMount(() => {
      store.dispatch(Actions.GET_GROUPS);
      store.dispatch(Actions.GET_STUDENTS);
    });

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Groups Listing", ["Apps", "Groups"]);
    });

    const toggleGroupsState = (groupsIds: Array<number>) => {
      groupsIds.forEach((id) => {
        toggleGroupState(id);
      });
    };

    const toggleGroupState = (id) => {
      store.dispatch(Actions.TOGGLE_GROUP_STATE, id);
    };

    return {
      displayedGroups,
      tableHeader,
      toggleGroupsState,
      toggleGroupState,
      checkedGroups,
      updateSelectedGroup,
      search,
    };
  },
});
