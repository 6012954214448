
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  onBeforeMount,
  computed,
  ComputedRef,
} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ITeacher } from "@/store/modules/TeacherModule";
import { IStudent } from "@/store/modules/StudentModule";
import { IGroup } from "@/store/modules/GroupModule";
import type { ElForm } from "element-plus";
type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  name: "create-group-modal",
  components: {},
  setup(props, context) {
    const store = useStore();
    const router = useRouter();
    const CreateGroupModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const formRef = ref<FormInstance>();
    const form = reactive({
      name: null,
      theoryTeacherId: null,
      drivingTeacherId: null,
      students: [],
      firstClassDate: null,
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: "Group name is required",
          trigger: "change",
        },
      ],
      theoryTeacherId: [
        {
          required: true,
          message: "Theory teacher is required",
          trigger: "change",
        },
      ],
      drivingTeacherId: [
        {
          required: true,
          message: "Driving teacher is required",
          trigger: "change",
        },
      ],
      students: [
        {
          type: "array",
          required: true,
          message: "Atleast 1 student is required",
          trigger: "change",
        },
      ],
      firstClassDate: [
        {
          type: "date",
          required: true,
          message: "First Class Date is required",
          trigger: "change",
        },
      ],
    });

    let teachers: ComputedRef<Array<ITeacher>> = computed(() => {
      return store.state.TeacherModule.teachers;
    });

    let students: ComputedRef<Array<IStudent>> = computed(() => {
      if (store.state.StudentModule.students) {
        return store.state.StudentModule.students.filter((student) => {
          return (
            student.cohort === null &&
            student.state === "ACTIVE" &&
            student.accountType === "CAR"
          );
        });
      } else return [];
    });

    onBeforeMount(() => {
      if (store.state.TeacherModule.teachers.length === 0)
        store.dispatch(Actions.GET_TEACHERS);

      if (store.state.StudentModule.students.length === 0)
        store.dispatch(Actions.GET_STUDENTS);
    });

    const submit = (formEl: FormInstance | undefined) => {
      if (!formEl) {
        return;
      }
      formEl.validate((valid) => {
        if (valid) {
          loading.value = true;
          store
            .dispatch(Actions.ADD_GROUP, form)
            .then((group) => {
              // update the state selectedStudent to the newly created student
              updateSelectedGroup(group);
              Swal.fire({
                text: "Group has been created successfully!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Continue",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                // Close modal after pressing confirm button
                const closeButton: any = document.querySelector(
                  "#kt_modal_create_group_cancel"
                );
                if (closeButton) closeButton.click();

                //reditect user to groupDetails page
                router.push({ name: "apps-groups-details" });

                var body: any = document.querySelector("#kt_body");
                body.style.overflow = "auto";
              });
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    const updateSelectedGroup = (group: IGroup) => {
      store.commit(Mutations.SET_SELECTED_GROUP, group);
    };

    return {
      form,
      teachers,
      students,
      rules,
      submit,
      formRef,
      loading,
      CreateGroupModalRef,
      updateSelectedGroup,
    };
  },
});
